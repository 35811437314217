import { Container, Text } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";

const TermsContent = () => {
  return (
    <Container>
      <div className="policy-content">
        <Text
          value="Customers Terms and Conditions for the Use of Parkway"
          variant="h4"
        />
        <Text variant="p18" as="div">
          <p>
            Parkway Projects Limited ("Parkway" or "We") is a Private Limited
            Liability Company. Parkway is a Central Bank of Nigeria licensed
            Payment Solution Service Provider and Mobile Money Operator.
          </p>

          <p>
            You are required to read and understand these rules as they are a
            binding agreement between you and Parkway. Customers who are under
            eighteen (18) years of age should review these terms and conditions
            with a parent or guardian.
          </p>

          <p>
            By registering for a Parkway Account or Wallet or using any of the
            services provided on this website, you agree to comply with and to
            be bound by these Terms of Use, including the Privacy Policy and all
            rules, guidelines, policies, terms, and conditions applicable to
            such service, and they shall be deemed to be incorporated into this
            Terms of Use and shall be considered as part and parcel of this
            Terms of Use.
          </p>

          <p>
            By agreeing to this Terms of Use, you acknowledge and agree that
            Parkway can maintain a deposit equivalent to your electronic value
            in one of its pool accounts domiciled at any of the CBN licensed
            banks or where applicable a third-party provider of the service you
            are using through Parkway.
          </p>
        </Text>

        <Text value="1. Definitions" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            The following definitions relate to these Terms and Conditions:{" "}
          </p>
          <p>
            1.1    "Account" or "Wallet" means an electronic stored value
            account on Parkway created for use by the Agent. Credits, debits and
            charges are applied to this Account. The Account is primarily
            accessed through your mobile phone, Parkway online systems, and
            mobile applications.
          </p>
          <p>
            1.2    "Agent" means any party or device, including authorized
            Parkway Agents, Merchants, ATMs, connected kiosks and bank tellers
            that facilitate Parkway transactions on behalf of customers.
          </p>
          <p>1.3    "ATM" means automated teller machine.</p>
          <p>
            1.4    "BVN" means Biometric Verification Number used by the banking
            industry in Nigeria and issued by a CBN approved financial
            institution.
          </p>
          <p>1.5    "CBN" means the Central Bank of Nigeria.</p>
          <p>
            1.6    "Content" means all information whether textual, visual,
            audio or otherwise, appearing on or available through the services.
          </p>
          <p>1.7    "Credit" means the movement of funds into an account.</p>
          <p>1.8     "Debit" means the movement of funds out of an account.</p>
          <p>
            1.9     "Level 1 customer" means a new customer who provides the
            minimum requirement listed in [4.9] below, to a Parkway agent or
            authorized administrative centre.
          </p>
          <p>
            1.10     "Level 2 customer" means a Parkway user who has met the
            conditions stated in [4.9] below to an authorized Parkway agent
            nationwide authorized to perform the upgrade.
          </p>
          <p>
            1.11     "Level 3 customer" means a Parkway user who has linked his
            Parkway account to his bank account with one of the banking
            institutions recognised by the Central Bank of Nigeria or provided
            all required KYC details at a Parkway administrative centre
            authorized to perform the upgrade to Level 3.
          </p>
          <p>
            1.12     "Linked bank account" means the bank account that you have
            linked to your Parkway account. This allows you to carry out
            transactions using funds from your bank account instead of using
            your Parkway account.
          </p>
          <p>
            1.13     "Merchant" means a seller of goods and or services who will
            accept Parkway as payment for these goods and or services.
          </p>
          <p>
            1.14     "Mobile Payments" means the service or process that allows
            customers to make and receive a variety of payments using their
            mobile phone, among other channels. This service is available as
            part of Parkway.
          </p>
          <p>
            1.15     "Mobile phone" means a GSM or CDMA device which can make
            and receive telephone calls and send and receive SMS, among other
            communication options.
          </p>
          <p>1.16     "NUBAN" means Nigerian Unified Banking Account Number.</p>
          <p>
            1.17     "Parkway" means the mobile money services offered by
            Parkway, with a central feature being a transactional account(s)
            that may be used in relation to a wide variety of services.
          </p>
          <p>
            1.18     "PIN" means personal identification number being the secret
            code you choose for secure use of (and access to) your Parkway
            account
          </p>
          <p>
            1.19     "Products" means any Parkway products, including
            person-to-person money transfer, bill payments, airtime top-up and
            Parkway retail payments services.
          </p>
          <p>
            1.20     "Registered phone number" means any phone number that has
            been registered on Parkway and tied to the Parkway Wallet.
          </p>
          <p>
            1.21     "Services" means any products and services provided to the
            customer as part of Parkway.
          </p>
          <p>
            1.22     "Parkway Wallet" means an electronic stored value account
            on Parkway used to perform transactions and not different from a
            normal bank account
          </p>
          <p>
            1.23     "Partner Service Provider" means any person or entity that
            offer its services or products through Parkway.
          </p>
          <p>
            1.24    "Transaction Savings Wallet" means an electronic stored
            value savings account on Parkway, used by the customer or Agent to
            save, earn interest and perform typical Parkway transactions
          </p>
          <p>
            1.25     "SMS" or "Short Message Service means a standard
            communication service on GSM phones which is used to exchange short
            text messages between mobile devices.
          </p>
        </Text>

        <Text value="2. The ReadyCash Offering" variant="h4" />

        <Text variant="p18" as="div">
          <p>
            2.1    ReadyCash is a service offered by Parkway and designed to
            allow you to:
          </p>
          <p>  2.1.1.    Sign up as a customer or Agent</p>
          <p>
              2.1.2.    access to our broad set of products and features within
            ReadyCash;
          </p>
          <p>  2.1.3    Credit a ReadyCash account with cash by:</p>
          <p>    2.1.3.1.   depositing at our agent locations</p>
          <p>
                2.1.3.2.   depositing money at any of our collections banks;
          </p>
          <p>
                2.1.3.3.   transferring funds into ReadyCash from a Linked bank
            account; or
          </p>
          <p>
                2.1.3.4.   receiving money into ReadyCash from another customer;
          </p>
          <p>
              2.1.4.   Pay or transfer money to another party for personal
            reasons or as payment for physical or virtual goods or services
            already received or to be received in the future;
          </p>
          <p>  2.1.5.   transfer to bank account;</p>
          <p>  2.1.6.   transfer to a ReadyCash account;</p>
          <p>  2.1.7.   pay Merchants;</p>
          <p>
              2.1.8.   receive money from another party for personal reasons or
            as payment for physical or virtual goods or services;
          </p>
          <p>
            2.2    Any available funds in your ReadyCash Account or Wallet can
            be utilized. However, the use of funds in your Transaction Savings
            Wallet will be subject to the restrictions set out in this Term of
            Use.
          </p>
          <p>
            2.3    These terms and conditions apply to all channels through
            which transactions may be carried out on ReadyCash, including SMS;
            Online/Internet; Online/Mobile phone application; USSD; and
            ReadyCash Agent network.
          </p>
          <p>
            2.4    We will endeavour to notify you of additional features that
            may be offered on ReadyCash and where necessary, the applicable
            means or requirements to activate any such features.
          </p>
        </Text>

        <Text value="3. Opening a ReadyCash Account" variant="h4" />

        <Text variant="p18" as="div">
          <p>
            3.1   In order to sign up to join ReadyCash, an active GSM or CDMA
            mobile phone subscription on any supported mobile network is
            required.
          </p>
          <p>
            3.2   Agents and Customers are limited to one (1) ReadyCash account
            per phone number. Multiple phone numbers can however be linked to
            the same account.
          </p>
          <p>
            3.3   To setup your account, ReadyCash requires your personal
            details including your name and phone number. You must provide
            complete and accurate information. Unregistered customers will be
            able to receive cash to their phone number. They will also have
            limited use of ReadyCash pending registration.
          </p>
          <p>
            3.4   In the process of verifying your identity, ReadyCash has the
            sole discretion to refuse any account opening application for any
            number of reasons.
          </p>
          <p>
            3.5   By giving ReadyCash your BVN, you authorize ReadyCash to
            collect and save your data from the BVN database as part of our KYC
            information to fulfil regulatory requirements. All KYC data
            collected will be treated as confidential. You also permit us to use
            your BVN to monitor, prevent and detect fraudulent activities and
            share the same with CBN authorised BVN stakeholders, for the purpose
            of deterring financial fraud.
          </p>
          <p>
            3.6   In the process of verifying your identity and BVN, ReadyCash
            has the sole discretion to refuse any Account or Wallet opening
            application for a number of reasons. In addition, we reserve the
            reserve the right to ask you before opening an Account and at any
            point during your use of ReadyCash for supplementary information and
            identification documents as well as any supporting documents that we
            may deem necessary.
          </p>
          <p>
            3.7   We reserve the right to screen all individuals and businesses
            against applicable sanction lists and the BVN watchlist database and
            may decline account opening applications in the event they are found
            to be on any of the lists.
          </p>
          <p>
            3.8   We reserve the right to immediately suspend or withdraw an
            Account or Wallet if we have reasonable grounds to believe that
            there may be a breach of security of that Account or Wallet, we
            suspect unauthorised or fraudulent use of that Account or we are
            required by law to do so.
          </p>
          <p>
            3.9   If a fraudulent activity is associated with the operation of
            your Account or Wallet, you agree that we have the right to apply
            restrictions to your Account and report to appropriate law
            enforcement agencies.
          </p>
        </Text>

        <Text value="4. Transacting" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            4.1   You may access the available funds in your account at any time
            using any of the channels you choose, including your mobile phone
            and the Internet. While we make every reasonable attempt to provide
            the services as described in our marketing and educational
            materials, we offer the services "as is" and without any warranties.
          </p>
          <p>
            4.2   All payments will be processed in Nigerian local currency i.e.
            Naira.
          </p>
          <p>
            4.3   Each transaction will be identified by a unique 5-character
            Transaction ID which is used to track and identify all transactions
            carried out on the ReadyCash network. This number is important for a
            variety of uses, including dispute resolution.
          </p>
          <p>
            4.4   ReadyCash will send you a message to verify and confirm all
            transactions effected from your ReadyCash account.
          </p>
          <p>
            4.5   Some transactions on ReadyCash’s USSD channel may require you
            to input your pre-configured PIN, you are solely responsible for
            maintaining the confidentiality of your PIN and login details of
            your Account. You must protect the secrecy of your PIN and login
            details and prevent fraudulent use of the same.
          </p>
          <p>
            4.6   As required by the CBN, there are daily limits on your Account
            or Wallet. You can increase or reduce these limits by providing
            ReadyCash with the required information, subject to the restrictions
            placed in terms of paragraphs 5.9.
          </p>
          <p>
            4.7   Your Account or Wallet will be credited with any deposits made
            into it, and the credit will be available for transactions
            immediately after they are cleared.
          </p>
          <p>
            4.8   We will verify and confirm any record of a deposit into your
            Account. Our records will be taken as correct unless the contrary is
            proved.
          </p>
          <p>
            4.9   The table below outlines the classification ReadyCash adopts
            along KYC levels along with the applicable transaction and balance
            limits. You may not withdraw, transfer or make any payments that
            together exceed any of the per transaction or daily limits defined
            for your category. Should you attempt to exceed any applicable
            limits, your transactions will be declined.
          </p>

          <div className="overflow-auto">
            <table className={table}>
              <thead>
                <tr>
                  <th className={tableData}>Agent Level</th>
                  <th className={tableData}>Verification Requirements</th>
                  <th className={tableData}>Daily limits</th>
                  <th className={tableData}>Account Balance Limit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={tableData}>Level 1</td>
                  <td className={tableData}>
                    BVN, Passport Photograph, Phone Number and Full Name
                  </td>
                  <td className={tableData}>₦ 50,000</td>
                  <td className={tableData}>₦ 300,000</td>
                </tr>

                <tr>
                  <td className={tableData}>Level 2</td>
                  <td className={tableData}>
                    Same as for Level 1, plus a Copy of Verifiable ID Card
                  </td>
                  <td className={tableData}>₦ 200,000</td>
                  <td className={tableData}>₦ 500,000</td>
                </tr>

                <tr>
                  <td className={tableData}>Level 3</td>
                  <td className={tableData}>
                    Same as for Level 2, plus additional KYC as would be
                    required when opening a bank account
                  </td>
                  <td className={tableData}>₦ 5,000,000</td>
                  <td className={tableData}>Unlimited</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            Parkway may, acting reasonably and in accordance with regulation set
            by the Central Bank of Nigeria, vary the limits at any time and any
            variation will be notified to customers. Where a deposit exceeding
            the transaction limit for your category is made to your Account, we
            will notify you to upgrade your Account to the category that applies
            to such deposit within seven (7) days. In the event that you fail to
            upgrade your Account to the required category, we reserve right to
            place a restriction on your Account until such upgrade to the
            required category is effected.
          </p>

          <p>
            {" "}
            4.10   For the avoidance of doubt, please note that some transaction
            limits may be further reduced from the approved CBN limits, this may
            be done based on channels or particular types of transactions as
            part of our risk control process.
          </p>

          <p>
            4.11   You undertake throughout the duration of your use of
            ReadyCash to comply with anti-fraud and money laundering regulations
            applicable in Nigeria. In particular, you undertake to respond
            diligently to any request from Parkway or a regulatory or judicial
            authority regarding any anti-fraud and money laundering activity on
            your Account
          </p>
          <p>
            4.12   Any breach of the anti-fraud and money laundering regulations
            by you constitutes a serious breach of this Terms of Use and we
            reserve the right to immediately suspend or close your Account and
            you indemnify us against any claims made in this regard.
          </p>
          <p>
            4.13   The proceeds from suspected fraudulent or money laundering
            transactions shall be kept by Parkway pending an internal decision
            or a judicial or administrative decision.
          </p>
        </Text>

        <Text value="5. Fees, Charges and Payment Terms" variant="h4" />

        <Text variant="p18" as="div">
          <p>
            5.1   When you make a payment, you authorize us (and our designated
            payment processor) to charge the full amount and any charges related
            to that transaction to the funding source you designate for the
            transaction. If your payment results in an overdraft or other fee
            from a related party, liability for such fees or penalties will rest
            solely with you.
          </p>

          <p>
            5.1   To prevent financial loss or possible violations of the law,
            Parkway reserves the right to use its discretion in disclosing
            details of any payments associated with you with funding source
            issuers, law enforcement agencies, or impacted third parties
            (including other users). Such disclosures will originate from an
            order of any trial court with the jurisdiction to compel such a
            disclosure.
          </p>
        </Text>

        <Text value="6. Merchant Transactions" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            6.1   When making a payment to a merchant using Parkway ReadyCash,
            you will be required to confirm the transaction. At this time you
            may also be requested to enter your PIN to accept the transaction.
            By entering your PIN to confirm the transaction you agree and
            confirm that you authorised the transaction. You cannot directly
            reverse or cancel any payment once it has been approved by you.
          </p>

          <p>
            6.2   When making a payment on a merchant website using Parkway, you
            will be required to log into your Parkway account, before payment
            can be made, in which case clause 5 will still be applicable.
          </p>

          <p>
            6.3   As we are unable to reverse or charge-back any payments made,
            should you have a dispute with any merchant, you should resolve such
            disputes with the merchant directly.
          </p>

          <p>
            6.4   Disputes between you and a merchant will not affect our right
            to recover payments from you.
          </p>
        </Text>

        <Text value="7. Security and Unauthorized Use" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            7.1   When signing up for a Parkway account, you will be prompted to
            create a unique Personal Identity Number (PIN), username, and
            password and provide answers to one or more security questions of
            your choosing. You are responsible for the safekeeping and proper
            use of these security data.
          </p>

          <p>
            7.2   You are responsible for all transactions that take place on
            your account with your PIN and you indemnify us against any claims
            made in respect of such transactions.
          </p>

          <p>
            7.3  If at any time you believe or discover that your PIN has been
            stolen or compromised, you should contact our Customer Services
            immediately. We will place your account on hold to prevent any
            transactions from being carried out as soon as we reasonably can.
            You will remain responsible for all transactions that occur until
            your account is put on hold.
          </p>

          <p>
            7.4   We may ask certain questions to confirm your identity when you
            call Customer Services. This method gives us your authorisation to
            service your Account and execute your instructions. However, please
            note that we will never ask for your Password or PIN. We advise that
            you do not disclose your Password or PIN to anyone under any
            circumstance.
          </p>

          <p>
            7.5   We use PIN and a selection of questions to confirm your
            identity when you call Customer Services. This method gives us your
            authorisation to service your account and execute your instructions.
          </p>

          <p>
            7.6   From time to time, we may investigate any actual, alleged or
            potential violations of this Terms and Conditions. You agree to
            cooperate fully in any of these inquiries.
          </p>
        </Text>

        <Text value="8. Deposit Insurance" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            Unless otherwise stated, all funds in your Parkway Account shall be
            insured up to the maximum coverage level for depositors in line with
            the Nigerian Deposit Insurance Act. This protection is provided by
            the Nigerian Insurance Deposit Corporation.
          </p>
        </Text>

        <Text value="9. Suspension and Closure of Accounts" variant="h4" />
        <Text variant="p18">
          <p>
            9.1   We may suspend, restrict, or terminate the provision of our
            services (in whole or in part) and or close your Account without any
            liability whatsoever under the following circumstances:
          </p>

          <p>
              9.1.1   Upon receiving a request from you at any time, we will
            close your account.
          </p>

          <p>
              9.1.2   If you notify us that your phone has been lost or stolen
            or your PIN has been compromised, we will suspend or close your
            account.
          </p>

          <p>
              9.1.3   if in any way we know or suspect your account is being
            used fraudulently, negligently or for illegal activities or if we
            must do so to comply with the law, we may close your account,
            restrict activity on your account or suspend access to your account
            and you indemnify us against any claims made in this regard.
          </p>

          <p>
              9.1.4   if we believe that you are in breach of these terms and
            conditions, are trying to compromise our systems, are unreasonably
            interfering with any services provided by us, or for any other
            purpose in protection of our interests, we may close your account.
          </p>

          <p>
              9.2   We will not be responsible to you for any direct, indirect,
            consequential or special damages arising from any act or omission by
            us or any third party for whom we are responsible, whether arising
            in contract, delict or statute, if we close or suspend your account
            in accordance with this Clause 9.
          </p>

          <p>
              9.3   In any case where your Parkway account is closed for any
            reason and has a balance of funds, upon your request to Customer
            Services, the balance will be made available to you for withdrawal
            barring any legal or regulatory provisions against such.
          </p>
        </Text>

        <Text variant="h4" value="10. Notices" />
        <Text variant="p18">
          <p>
            10.1   The physical address, email address or telephone number you
            supply during sign-up are regarded as the preferred channels via
            which notices may be given and documents in legal proceedings may be
            served. You must notify us immediately should your physical, postal,
            email address or mobile phone number change.
          </p>

          <p>
            10.2   We are entitled to send information to you via SMS to the
            registered phone number associated with your account and as amended
            from time to time.
          </p>

          <p>
            10.3   We are entitled to send any notice to an email address
            specified on your account. This clause pertains to customers who
            have accessed and used Parkway services online or via the Parkway
            mobile application.
          </p>

          <p>
            10.4   Any correspondence that we send to you by courier or post
            will be considered to have arrived within seven (7) days of sending
            and any correspondence that we send to you by email or SMS will be
            considered to have arrived on the day that it was sent to you,
            unless the contrary is proved.
          </p>

          <p>
            10.5   You should send any legal notice to us by post to our address
            below: 295 Herbert Macaulay Way, Yaba, Lagos, Nigeria
          </p>
        </Text>

        <Text value="11. Privacy Policy" variant="h4" />
        <Text variant="p18">
          <p>
            11.1   When you sign up for Parkway you will be required to provide
            us with your name, email, gender, and birth date, among other
            information. In some cases we may ask for additional information for
            security reasons or to provide specific services to you.
          </p>

          <p>
            11.2   You may change your personal identifiable information on
            Parkway at any time by contacting Customer Service or accessing your
            account profile online. Such information will be updated
            immediately.
          </p>

          <p>
            11.3   When you use Parkway from a computer, mobile phone, or other
            device, we may collect information from that device regarding your
            browser type, location, and IP address, as well as the pages you
            visit for security purposes.
          </p>

          <p>
            11.4   We are required to retain the details of transactions or
            payments you make via Parkway after the transaction is completed.
            This information will only be made available to third parties if
            required by law. However, we commit to keeping all your personal
            information, including your funding source details safe.
          </p>

          <p>
            11.5   You agree that your information, including your personal
            information, your telephone conversations with our Customer Services
            and your transactions may be recorded and stored for record keeping
            purposes for up to 5 years from date of closure of account.
          </p>

          <p>
            11.6   We use server firewalls and encryption to keep your account
            information safe during transmission and in storage. We also use
            automated and social measures to enhance security, such as analyzing
            account behaviour for fraudulent or otherwise anomalous behaviour,
            may limit use of Parkway features in response to possible signs of
            abuse, and may suspend or disable accounts for violations of our
            customer terms and conditions.
          </p>

          <p>
            11.7   Unless stated otherwise, our current privacy policy applies
            to all information that we have about you and your account. If we
            make changes to this Privacy Policy we will notify you.
          </p>
        </Text>

        <Text value="12. Disputes and Refunds" variant="h4" />
        <Text variant="p18">
          <p>
            12.1   If you believe that an unauthorized or otherwise problematic
            transaction has taken place under your account, you agree to notify
            us immediately, to enable us take action to help prevent financial
            loss.
          </p>

          <p>
            12.2   All claims against us related to payments should be made
            within thirty (30) days after the date of such payment. It will be
            taken that you waive all claims against us, to the fullest extent of
            the law after the said period of time.
          </p>

          <p>
            12.3   You are responsible for and agree to indemnify us for all
            Refunds, charge-backs, claims, fees, fines, penalties and other
            liability incurred by us (including costs and related expenses)
            caused by or arising from payments that you authorized or accepted.
          </p>

          <p>
            12.4   An email notification will be sent for any chargeback
            request. However, if we do not get a confirmation from you for said
            chargeback request within 48hours, your account will be debited for
            the value.
          </p>

          <p>
            12.5   Your only remedy for a technical failure or interruption of
            service is to request that your transaction be completed at a later
            time.
          </p>

          <p>
            12.6   It is your responsibility to remit any taxes that apply to
            your transactions and not normally included in the cost of the
            Parkway transaction. You agree to indemnify and hold us harmless
            from and against any claim arising out of your failure to do so.
          </p>

          <p>
            12.7   The transaction ID and transaction details will be required
            to resolve all disputes.
          </p>

          <p>
            12.8   Unless stated otherwise, our current privacy policy applies
            to all information that we have about you and your account. If we
            make changes to this Privacy Policy we will notify you.
          </p>
        </Text>

        <Text value="13. General" variant="h4" />
        <Text variant="p18">
          <p>
            13.1   We try to keep Parkway available at all times, bug-free, and
            safe, but you use it at your own risk. We are providing Parkway "as
            is" without any express or implied warranties including, but not
            limited to, implied warranties of merchantability, fitness for a
            particular purpose, and non-infringement. Parkway is not responsible
            for the actions, content, information, or data of third parties, and
            you release us, our directors, officers, employees, and agents from
            any claims and damages, known and unknown, arising out of or in any
            way connected with any claim you have against any such third
            parties.
          </p>

          <p>
            13.2   We reserve the right to change, modify, add or delete
            portions of these Terms of Use, at any time without prior notice to
            you. It is your responsibility to check these Terms and Conditions
            periodically for changes. Your continued use of the website or
            Parkway following the posting of changes will mean that you accept
            and agree to the changes.
          </p>

          <p>
            13.3   You are responsible for your connection to the mobile phone
            networks, the Internet and all costs associated with these same
            connections.
          </p>

          <p>
            13.4   We are not responsible for any loss arising from any failure,
            malfunction, or delay in any mobile phone networks, mobile phones,
            ATMs, the Internet or terminals or any of its supporting or shared
            networks, resulting from circumstances beyond our reasonable
            control.
          </p>

          <p>
            13.5   Transactions carried out by you are defined as non commission
            sales hence you are allowed to charge a fee for transactions.
          </p>

          <p>
            13.6   We may allocate any money received from you or held on your
            behalf to settle any outstanding balance on your account.
          </p>

          <p>
            13.7   All copyright, trademarks and other intellectual property
            rights used as part of our services or contained on our websites,
            documents or other materials are owned by Parkway, or its licensors.
            You agree that you acquire no rights thereto.
          </p>

          <p>
            13.8   You acknowledge and agree that these Terms and Conditions are
            governed by Nigerian law and any breach of these Terms and
            Conditions will be considered as having taken place in Nigeria.
          </p>

          <p>
            13.9   The Parties shall use their best efforts to amicably settle
            all disputes arising out of or in connection with the performance or
            interpretation of this Terms and Conditions. Any dispute or
            differences arising out of the construction, interpretation or
            performance of the obligations created under this relationship which
            cannot be settled amicably within one (1) month after receipt by a
            party of the other party's request for such amicable settlement may
            be referred to a single arbitrator to be appointed in accordance
            with the Arbitration and Conciliation Act. Cap. A18, Laws of
            Federation of Nigeria 2004. The place of arbitration shall be in
            Lagos, Nigeria and the language of arbitration shall be English
            language.
          </p>
        </Text>
      </div>
    </Container>
  );
};

const table = ctl(`
table-fixed
border-collapse
border
border-solid
`);

const tableData = ctl(`
border
text-left
p-3
`);

export { TermsContent };
