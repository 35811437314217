import { PageHeader } from "components";
import React from "react";

const TermsPageHeader = () => {
  return (
    <PageHeader
      title={<>Parkway’s Terms of Service</>}
      subheading={
        <>
          Please read the following Terms of Service carefully. By using any
          Parkway product or this website, you are agreeing to these Terms of
          Service.
        </>
      }
      center
      theme="light"
    />
  );
};

export { TermsPageHeader };
