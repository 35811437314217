import * as React from "react";

import { Layout } from "components";

import { TermsContent, TermsPageHeader } from "templates/terms-of-service";

const ContactUsPage = () => (
  <Layout title="Terms of Use" theme="light">
    <TermsPageHeader />
    <TermsContent />
  </Layout>
);

export default ContactUsPage;
